<template>
    <div class="row mb-5 align-items-center">
        <div class="col-12">
            <ValidationObserver ref="creditPriceForm">
                <div class="row">
                    <div class="col-12">
                        <ValidationProvider name="academic_year" rules="required" v-slot="{ valid, errors }">
                            <b-form-group>
                                <label class="col-form-label pt-0">{{ $t('academic_year') }}</label>
                                <academic-years-selectbox :active-years="true"
                                                          v-model="creditPriceForm.academic_year"></academic-years-selectbox>
                                <b-form-invalid-feedback v-if="errors[0]"
                                                         v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="fall_credit" rules="" v-slot="{ valid, errors }">
                            <b-form-group>
                                <label class="col-form-label pt-0">{{ $t('fall_credit') }}</label>
                                <b-form-input type="number" step="1"
                                              v-model="creditPriceForm.fall_credit"></b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]"
                                                         v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="spring_credit" rules="" v-slot="{ valid, errors }">
                            <b-form-group>
                                <label class="col-form-label pt-0">{{ $t('spring_credit') }}</label>
                                <b-form-input type="number" step="1"
                                              v-model="creditPriceForm.spring_credit"></b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]"
                                                         v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>

                    <div class="col-12">
                        <b-button type="submit" @click="saveCreditPrice" variant="primary">{{
                                $t('save')
                            }}
                        </b-button>
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
//Component
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
//Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import PaymentPlanService from "@/services/PaymentPlanService";
//Service

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        ProgramSelectbox,
        FacultySelectbox,
        StatusSelectbox,
        AcademicYearsSelectbox

    },
    props: {
        studentProgramId: {
            default: null
        }
    },
    data() {
        return {
            formLoading: false,
            creditPriceForm: {
                fall_credit: null,
                spring_credit: null,
                academic_year: null
            }
        }
    },
    methods: {
        async saveCreditPrice() {
            if (!this.creditPriceForm.fall_credit && !this.creditPriceForm.spring_credit) {
                this.$toast.error(this.$t('fill_at_least_one_field'));
            }

            const isValid = await this.$refs.creditPriceForm.validate();
            if(!isValid){
                return;
            }

            this.creditPriceForm.student_program_id = this.studentProgramId;
            PaymentPlanService.credit(this.creditPriceForm).then(response => {
                this.toast(response.data);
                this.$emit('creditFormSaved');
            })
                .catch(e => {
                    this.showErrors(e, this.$refs.creditPriceForm);
                });
        },
        getCredits() {
            if (!this.creditPriceForm.academic_year) {
                return;
            }
            PaymentPlanService.getCreditsByAcademicYear(this.studentProgramId, this.creditPriceForm.academic_year)
                .then(response => {
                    this.creditPriceForm.fall_credit=null;
                    this.creditPriceForm.spring_credit=null;
                    if(!response.data.data){
                        return;
                    }
                    if (response.data.data[1]) {
                        this.creditPriceForm.fall_credit = parseFloat(response.data.data[1].credits);
                    }
                    if (response.data.data[2]) {
                        this.creditPriceForm.spring_credit = parseFloat(response.data.data[2].credits);
                    }
                })
                .catch(e => {
                    this.showErrors(e, this.$refs.creditPriceForm);
                });

        }
    },
    watch: {
        'creditPriceForm.academic_year'(newVal) {
            this.getCredits();
        },
    },
    created() {
        this.getCredits()
    }
}
</script>
